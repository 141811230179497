
















































import { Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { GM } from '@/api'
import { URL_REGEX } from '@/services/Validation/regexes'

@Component
export default class AddPunishmentProofModal extends Mixins(Toast) {
  isOpen = false
  showError = false
  punishmentLog: Nullable<PunishmentLog> = null
  proofs: string[] = ['']

  async mounted() {
    this.$root.$on('openAddPunishmentProofModal', punishmentLog => {
      this.isOpen = true
      this.punishmentLog = punishmentLog
    })
  }

  validate() {
    return this.proofs.filter(Boolean).every(e => URL_REGEX.test(e as string))
  }

  async addProofs(e) {
    e.preventDefault()
  
    try {
      if (!this.validate()) {
        this.showError = true
        return
      }

      await GM.addProofToPunishment({
        id: this.punishmentLog!.id,
        punishmentType: this.punishmentLog!.penaltyType ? 1 : 0,
        proofs: this.proofs.filter(Boolean)
      })

      this.$root.$emit('refreshPunishmentsTable')
      this.showSuccessToast({
        title: 'Successfully added proof!',
        // @ts-ignore
        message: `Proofs has been added on Punishment Log #${this.punishmentLog.id}`
      })
      this.proofs = []
      this.isOpen = false
    } catch(e) {
      this.showErrorToast({
        title: 'Can\'t add proof to Punishment Log',
        message: 'An error occurred while trying to add a proof'
      })
    }
  }
}
